<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>アカウント</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('addUser.addUser')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-user"></i>&nbsp;{{$t('addUser.account')}}</span>
      </div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <!--<el-form-item :label="$t('addUser.loginName')" prop="loginName">
          <el-input v-model="form.loginName" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>-->
        <el-form-item :label="$t('addUser.mail1')" prop="mail">
          <el-input v-model="form.mail" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="CC" prop="cc">
          <el-input v-model="form.cc" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('addUser.name')" prop="name">
          <el-input v-model="form.name" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('addUser.password')" prop="password">
          <el-input v-model="form.password" maxlength="20" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('addUser.role')" prop="role">
          <el-radio v-model="form.role" label="4">代理店社員</el-radio>
          <el-radio v-model="form.role" label="3" v-if="accountInfo.role <= 3">PP（営業）メンバー</el-radio>
          <el-radio v-model="form.role" label="2" v-if="accountInfo.role <= 3">PP（庶務）メンバー</el-radio>
          <el-radio v-model="form.role" label="1" v-if="accountInfo.role <= 3">システム管理員</el-radio>
        </el-form-item>
        <el-form-item :label="$t('addUser.group')" prop="groupId">
          <el-select v-model="form.groupId" placeholder="キーワードを入力してください。" filterable remote :remote-method="queryGroupList" clearable style="width: 70%;">
            <el-option
              v-for="item in groupList"
              v-bind:key="item.groupId"
              v-bind:label="item.groupName"
              v-bind:value="item.groupId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('addUser.mobile')">
          <el-input v-model="form.mobile" maxlength="11" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="submitForm('form')">{{$t('addUser.create')}}</el-button>
          <el-button round @click="resetForm()">{{$t('addUser.back')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { addUser,listGroup } from "@/api/admin";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "AddUser",
  data() {
    
    return {
      backupInfo: { restInPlan: [] },
      groupList: [],
      form: {
        loginName: null,
        name: "",
        mobile: "",
        mail:"",
        cc:"",
        role:"4",
        password:"",
        groupId:""
      },
      rules: {
        name: [
          { required: true, message: "ユーザー名を入力してください。", trigger: "blur" }
        ],
        password: [
          { required: true, message: "パスワードを入力してください。", trigger: "blur" },
          { pattern: /[\x00-\xff]{8,20}$/, message: "最小8桁は最大20桁で、半角英数字と半角記号しか入力できません。", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "携帯を入力してください。", trigger: "blur" },
          { min: 11, max: 11, message: "携帯の長さは11桁です。", trigger: "blur" }
        ],
        mail: [
          { required: true, message: "メールアドレスを入力してください。", trigger: "blur" },
          { min: 6, max: 50, message: "最小6桁は最大50桁で、半角英数字と半角記号しか入力できません。", trigger: "blur" }
        ],
        cc: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "CCを正しく入力してください。", trigger: "blur" }
        ],
        groupId: [
          { required: true, message: "グループを入力してください。", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    //this.refreshData();
    this.queryGroupList(null);
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    queryGroupList(keyword) {
      listGroup({
        groupId: this.accountInfo.groupId,
        pageSize: 80,
        keyword: keyword
      }).then((response) => {
        this.groupList = response.data.items;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          addUser({
            id: this.accountInfo.id,
            groupId: this.accountInfo.groupId,
            ...this.form
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            //this.refreshData();
            this.$router.push(ROUTER_PATH.ADMIN_USER);
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$router.push(ROUTER_PATH.ADMIN_USER);
    }
  }
};
</script>
